import React from 'react';
import './App.css';
import {APIGet} from "./fetchBuilder";

const REACT_APP_RESOLVER_URL = "https://sandbox.qrcode.link/t/";

function App() {

  const [loading, setLoading] = React.useState(false);
  const [errored, setErrored] = React.useState(false);
  const [content, setContent] = React.useState<any | null>(null);

  React.useEffect(() => {

    setLoading(true);

    const codeID = window.location.href.split("/")[window.location.href.split("/").length - 1];

    APIGet<any>(REACT_APP_RESOLVER_URL + codeID).then(data => {
        if (data.status > 200) {
            setErrored(true);
            return;
        }

        if (data.parsedBody !== undefined) {
            console.log(data.parsedBody);
            setContent(data.parsedBody);
        }

    }, (error) => {
      setErrored(true);
    })

  }, []);

  return (
    <div className="App">
      <div className="Title">
          {content?.title}
      </div>
      <div className="Content">
          {content?.content}
      </div>
      <footer className="Footer">
        Powered by Unitag
      </footer>
    </div>
  );
}

export default App;
